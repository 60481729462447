

  export const marksNOAAMix = {

    4: {
      style: {      
      	color: 'white',
        fontSize: '1rem',
      },
      label: '14 days',
    },
    3: {
      style: {      
      	color: 'white',
        fontSize: '1rem',
      },
      label: '30 days',
    },
    2: {
      style: {  
      	color: 'white',    
        fontSize: '1rem',
      },
      label: '180 days',
    },
    1: {
      style: {  
      	color: 'white',    
        fontSize: '1rem',
      },
      label: '365 days',
    },
    0: {
      style: {   
      	color: 'white',   
        fontSize: '1rem',
      },
      label: 'YTD',
    },
    5: {
      style: { 
      	color: 'white',     
        fontSize: '1rem',
      },
      label: 'Last 7 days',
    },    
    6: {
      style: {   
        color: 'white',
        fontSize: '1rem',
      },
      label: 'Today',
    }
  };