import { useState, useEffect, useMemo } from "react";
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import LayerItem from "./LayerItem";
import LayerConfig from '../../LayerConfig';
import "./layer-transparency.scss";


const LayerTransparency = ({ global }) => {            
  let items = useMemo(() => { return LayerConfig.filter((item) => item.type === 'maplayer').map(layer => ({
              initialOpacity: layer["initialOpacity"]|| 0.5,
              name: layer["name"],
              id: layer["id"],
              opacity: global[layer["opacity"]],
              setOpacity: global[layer["setOpacity"]],
              selected: global[layer["selected"]],
              setSelected: global[layer["setSelected"]],
              brightness: global[layer["brightness"]],
              setBrightness: global[layer["setBrightness"]],
              hasBrightness: (layer["hasBrightness"] ? true : false)
         }))
      })

  const [layerItems, setLayerItems] = useState(items);
  const [selectedItems, setSelectedItems] = useState([]);
  
  useEffect(() => {
    setLayerItems((prevItems) => {
      if (JSON.stringify(prevItems) !== JSON.stringify(items)) {
        return items;
      }
      return prevItems;
    });
  }, [items]);

  useEffect(() => {
    let array = [];

    layerItems.forEach((layerItem) => {
      if (layerItem.selected) array.push(layerItem);
    });

    setSelectedItems(array);
  }, [layerItems]);

  const handleOrder = ({ source, destination }) => {
      if(source && (typeof source.index !=='undefined') && destination && (typeof destination.index !=='undefined')){
        const newArray = arrayMove(selectedItems, source.index, destination.index)
        setSelectedItems(newArray)       
      }
  };

  useEffect(() => {
    let ids = []
    let names = []
    if(selectedItems) {
      selectedItems.forEach(({ id }) => ids.push(id))
      selectedItems.forEach(({ name }) => names.push(name))
    }
    global.setLayerIds(ids)
    global.setPillNames(names)
  },[selectedItems])

  return (
    <DragDropContext  onDragEnd={handleOrder}>
      <Droppable droppableId="droppable-1">
        {(provided) => (
        <div
          data-drop="true"
          ref={provided.innerRef}
          className="transparency-container"
          style={{ padding: "16px", marginTop: 20 }}
          >
          {selectedItems.map((item, index) => {
            if(item.selected) {
              return <LayerItem
                visibility={item.visibility}
                key={item.name}
                name={item.name}
                index={index}
                opacity={item.opacity}
                setOpacity={item.setOpacity}
                selected={item.selected}
                setSelected={item.setSelected}
                initialOpacity={item.initialOpacity ? item.initialOpacity : 0.5}
                brightness={item.brightness}
                setBrightness={item.setBrightness}
                hasBrightness={item.hasBrightness}
              />
            }
          })}
          {provided.placeholder}
        </div>
        )}
      </Droppable> 
    </DragDropContext>
  );
};

export default LayerTransparency;

