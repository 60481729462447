import { useEffect, useState, useContext, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { PointContext } from "../../../PointContext";
import { RefContext } from "../../../RefContext";
import ReportTable from "../shared/ReportTable";
import settings from "../../../settings.json";
import MapAddition from "../shared/MapAddition";
import ReportHeader from "../shared/ReportHeader";
import { Row, Col, Form } from "react-bootstrap";
import ReportFooter from "../shared/ReportFooter";
import LineChart from "./LineChart";
import { putViewportIntoStorage } from "../../utils";
import { Map } from "react-map-gl";
import CylindricalLithologyChart from "./CylindricalLithologyChart";
import MapLegend from "../shared/MapLegend";
import "./api-table-report.scss";


const APITableReport = ({ global, reportID, datasets, api }: any) => {
 const fullscreenhandle = useFullScreenHandle() as any;
 const mapRef = useRef<any>();


 const { currentPoint } = useContext<any>(PointContext);
 const { setCurrentRef } = useContext<any>(RefContext);


 const [dataset, setDataset] = useState(datasets?.[0] || "");
 const [id, setId] = useState();
 const [reportData, setReportData] = useState();
 const [lithologyData, setLithologyData] = useState();


 const generateColumns = (dataArray: Array<Record<string, any>>) => {
   if (!dataArray || dataArray.length === 0) return [];
   const uniqueKeys = new Set<string>();
   dataArray.forEach((dataObject) => {
     Object.keys(dataObject).forEach((key) => uniqueKeys.add(key));
   });
   return Array.from(uniqueKeys).map((key) => ({
     Header: key,
     accessor: key,
   }));
 };


 const getData = async (table: string, id: string) => {
   global.setLoading(true);
   try {
     const { body } = await api(table, id);
     const sortedData = body.sort(
       (a: any, b: any) =>
         new Date(a.record_date).getTime() - new Date(b.record_date).getTime()
     );
     setReportData(sortedData);
   } catch (error) {
     console.error(error);
   } finally {
     global.setLoading(false);
   }
 };


 const generateRandomHexColor = () =>
   `#${Math.floor(Math.random() * 0xffffff).toString(16).padStart(6, "0")}`;


 const handleLithologyData = (input: any) => {
   const array = input.map(
     ({ start_depth, end_depth, lithology_description }: any) => {
       const height = end_depth - start_depth;
       return {
         startHeight: start_depth,
         height,
         color: generateRandomHexColor(),
         label: lithology_description,
         radius: 6,
       };
     }
   );
   setLithologyData(array);
 };


 useEffect(() => {
   if (reportData) handleLithologyData(reportData);
 }, [reportData]);


 useEffect(() => {
   if (dataset && id) getData(dataset, id);
 }, [dataset, id]);


 useEffect(() => {
   global.setUserLogged(true);
   setCurrentRef(mapRef);
 }, []);


 const flyToPoint = (lng: any, lat: any, map: any) => {
   return map.flyTo({
    center: [lng, lat],
    essential: true,
    zoom: 10,
  });
 };


 useEffect(() => {
   if (currentPoint && mapRef) {
     const { lat, lng, id } = currentPoint;
     setId(id);
     setTimeout(() => flyToPoint(lng, lat, mapRef?.current), 300)
   }
 }, [currentPoint]);




 return (
   <Col className="api-container-map-container">
     <FullScreen handle={fullscreenhandle}>
       <Row className="api-map-container">
         <Row className="header">
           <ReportHeader
             global={global}
             data={[currentPoint]}
             mapRef={mapRef}
             reportID={reportID}
             fullScreenClickHandle={fullscreenhandle.enter}
           />
         </Row>
         <Row className="map">
           <Map
             id={`TableReport-map ${reportID}`}
             mapboxAccessToken={settings.maboxKey}
             mapStyle={global.mapStyle}
             preserveDrawingBuffer={true}
             ref={mapRef}
             projection={global.globeView ? "globe" : "mercator" as any}
             onClick={(e) => global.onMapClick(e)}
             onMove={(e) => {
               putViewportIntoStorage({
                 longitude: e.viewState.longitude,
                 latitude: e.viewState.latitude,
                 zoom: e.viewState.zoom,
               });
               global.setViewport({
                 longitude: e.viewState.longitude,
                 latitude: e.viewState.latitude,
                 zoom: e.viewState.zoom,
               });
             }}
           >
             <MapAddition
               global={global}
               mapRef={mapRef}
               displayControlsDefault={false}
               drawPlacement={"bottom-left"}
               position={"low-adjusted"}
               zipOff={true}
             />
             <div className="map-legend-container">
               <MapLegend legendWidth={300} global={global} />
             </div>
           </Map>
         </Row>
       </Row>


       <Form.Control
         onChange={({ target }: any) => setDataset(target.value)}
         className="dataset-select-menu"
         as="select"
       >
         {datasets && datasets.length > 0 ? (
           datasets.map((dataset: string, index: number) => (
             <option key={index} value={dataset}>
               {dataset}
             </option>
           ))
         ) : (
           <option value="" disabled>
             No datasets available
           </option>
         )}
       </Form.Control>


       {reportData && lithologyData && dataset === "lithology" && (
         <Row className="lithology-container">
           <CylindricalLithologyChart data={lithologyData} />
         </Row>
       )}


       <Row className="api-table-container">
         <Row className="table">
           {reportData && dataset === "lithology" && (
             <ReportTable
               customWidth={"100%"}
               data={reportData}
               columns={generateColumns(reportData)}
             />
           )}
           {reportData && dataset === "daily_water_levels" && (
             <LineChart
               global={global}
               data={reportData}
               title={"water levels"}
             />
           )}
         </Row>
       </Row>


       <ReportFooter />
     </FullScreen>
   </Col>
 );
};


export default APITableReport;