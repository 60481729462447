import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import settings from "./../../settings.json";
import { useState, useContext, useEffect, useMemo } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { Scrollbars } from 'react-custom-scrollbars-2';


const WaterWatch = ({ id, url, opacity, global }: any) => {

  let _currentUrl = `https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/water/strmflow-current.geojson`

  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [category, setCategory] = useState<any>('current');

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;

  useEffect(() => {
      if(global.layerMode && global.layerMode.layer==='WaterWatch'){
        setCategory(global.layerMode.category)
      }
  }, [global.layerMode]);

  useEffect(() => {
      if(category){
        setCurrentUrl(`https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/water/strmflow-${category}.geojson`)
      }
  }, [category]);

  useEffect(() => {
      const handleClick = (event: any) => {
        const queryObject = mapInstance.queryRenderedFeatures(event.point);
        let layerObject = [] as any; 
        for (let i = 0; i < queryObject.length + 1; i++) {
          if (queryObject[i] && queryObject[i].layer && queryObject[i].layer.id === 'waterWatchSites-layer') {
            queryObject[i].properties["centroid_longitude"] = event.lngLat.lng;
            queryObject[i].properties["centroid_latitude"] = event.lngLat.lat
            layerObject.push(queryObject[i].properties);
          }
        }
        console.log(queryObject, layerObject, layerObject.length)  
        if(layerObject && layerObject.length >0 ){
          console.log('pop')
            setPopupInfo(layerObject[0])
        }
     
      };

      if (mapInstance) {
          mapInstance.on('click', handleClick);
      }

      return () => {
          if (mapInstance) {
              mapInstance.off('click', handleClick);
          }
      };
  }, [mapInstance]);



    const renderFields = (objectMap: object) => {
      //@ts-ignore
      let siteCode = objectMap.STAID;
        return Object.entries(objectMap).map((element: any, index:any) => {
          const skipFields = ['layerName', 'counts', 'results', 'radius'];
          if(skipFields.indexOf(element[0])>-1){
            return null;
          } else if (element[0] === 'URL' ) {
              return (
                <div key={uuidv4()}>
                  <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{marginBottom: 10, opacity: 1}}>  
                        <a href={`https://waterdata.usgs.gov/monitoring-location/${siteCode}/`} target={'_blank'}>Navigate to USGS Monitoring Report</a>
                  </span>
                  </p>
                  <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{marginBottom: 10, opacity: 1}}>  
                        <a href={`https://waterdata.usgs.gov/nwis/inventory?site_no=${siteCode}&agency_cd=USGS`} target={'_blank'}>Navigate to USGS Inventory Report</a>
                  </span>
                  </p>
                </div>
              )      
          } else if (element[0] === 'siteUrl') {
            return (
              <div>
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{'Site info:'}</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>  
                    <a href={element[1]} target={'_blank'}>Navigate to more site info at EPA</a>
                  </span>
                </p>

              </div>
              )        
          } else if (element[0] === 'STAID') {
            return (
              <div  style={{paddingTop: 10}}>
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{element[0]}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {element[1]} </span>
                </p>

              </div> 
              )        
          } else if (element[0] === 'centroid_latitude') {
            return (
              <div  style={{paddingBottom: 20}}>
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{element[0]}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {element[1]} </span>
                </p>

              </div> 
              )        
          }  else {
            return (
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{element[0]}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {element[1]} </span>
                </p>
              )        
          }
        })
    }

/*
{
    "STAID": "05550001",
    "STANAME": "FOX RIVER (TAILWATER) AT ALGONQUIN, IL",
    "ST": "il",
    "HUC": "07120006",
    "CLASS": 0,
    "FLOW": 630,
    "STAGE": 5.3,
    "TIME": "2024-12-16 05:30:00",
    "TIME_UTC": "2024/12/16",
    "URL": "https://waterdata.usgs.gov/monitoring-location/05550001",
    "DATUM": "NAD83",
    "COUNT": 15,
    "PERCENTILE": 25.63,
    "FLOODSTAGE": 9.5,
    "centroid_longitude": -88.23536544779967,
    "centroid_latitude": 42.14830681481979
}*/

    const renderThumb = (style: any) => {
      const thumbStyle = {
        backgroundColor: 'white'
      };
      return (
        <div style={{ ...style, ...thumbStyle }} />
      );
    }



    const renderPopup = () => {
      return(
        <Popup
            longitude={Number(popupInfo.centroid_longitude)}
            latitude={Number(popupInfo.centroid_latitude)}
            closeButton={true}
            closeOnClick={true}
            className="wqp"
            onClose={() => setPopupInfo(null)}>
          <div className="popup-container">
            <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: '100%', height: '100%' }}>
              {renderFields(popupInfo)}
            </Scrollbars>
          </div>
        </Popup>
      )
    }




//flow, huc  STAID, STANAME   url
//https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/water/strmflow-current.geojson?time=1734009300000
//https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/water/strmflow-7day.geojson?time=1734009300000
//https://storage.googleapis.com/noaa-nidis-drought-gov-data/current-conditions/json/v1/sector/water/strmflow-28day.geojson?time=1734009300000 
/*


This map shows streamflow conditions averaged over the last 28 days, compared to historical conditions for the same time period. Click on a streamgage to view current data from the U.S. Geological Survey.

Source(s): U.S. Geological Survey

https://waterwatch.usgs.gov/index.php?m=real&w=kml&r=us&regions=all

https://waterdata.usgs.gov/monitoring-location/12342500

https://waterwatch.usgs.gov/?m=real&r=nc&w=map

https://waterwatch.usgs.gov/index.php?id=flood_cumflow
https://waterdata.usgs.gov/nwis/dv?referred_module=sw&site_no=06892350


*/

/*


        <CircleVectorLayer
          global={global}
          id="waterWatchSites"
          opacity={global.waterWatchOpacity}
          // @ts-ignore
          tileSet={settings.tileSetURLs.USGSWaterWatch}
          sourceLayer={"water_watch_sites"}
          circleColor={`${adjustHexBrightness("#767ED6", global.waterWatchBrightness)}`}
          action={global.action}
          radius={4}
          zoom={zoom}
          wws={true}
        />


 */

  return (
    <div>
    <Source
      id={`${id}-layer`}
      key={`${id}-layer`}
      type="geojson"// @ts-ignore
      data={currentUrl}
    >
        {/* @ts-ignore */}
        <Layer      
          beforeId={settings.defaultBeforeId}
          paint={{           "circle-color": [
                "interpolate",
                  ["linear"],
                    ['get', 'PERCENTILE'],
                    0, `transparent`,
                    0.001, `#ff0000`,
                    1, `#b12121`,
                    10, `#ffa400`,
                    25, `#00ff00`,
                    75, `#40dfd0`,
                    90, `#0000ff`,
                    99, `#000000`
            ], }}
          type="circle" id={`${id}-layer`}
          source={`${id}-layer`}       
         /> 
       {popupInfo && renderPopup()}
      </Source>

    </div>
  )
}

export default WaterWatch