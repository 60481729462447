import { v4 as uuidv4 } from 'uuid';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';


const MapLegendColorPalette = ({
  colorPaletteArray = [],
  tickArray = [],
  widthSegment= 20,
  title='Water Level Disturbance',
  colormap='interpolateTurbo',
  mode=''
}: {
  colorPaletteArray?: any;
  tickArray?: any;
  widthSegment?: number;
  title?:string;
  colormap?:string;
  mode?:string;
}): JSX.Element => {

  let thisColorMap = colormap;

  const tickExtent = d3Array.extent<any, any>( tickArray, d => d);
  let legendScale = d3Scale.scaleLinear()
    .range([0, 1])
    .domain([0, tickArray.length]);

  if(mode='waterwatch'){
    legendScale = d3Scale.scaleLinear()
    .range([0, 1])
    .domain([tickArray.length-1, 0]);
  }

  let colorArray = tickArray.map((item: any, index:any) => {//@ts-ignore
        return {value: item, color: d3ScaleChromatic[thisColorMap](legendScale(index)), index: index, indexValue:legendScale(index)}
  })

  if(mode===''){
    colorArray.push({value: 3, color:'#b11892', index: colorArray.length, indexValue:legendScale(colorArray.length)})
    colorArray.push({value: '3+m', color:'#891672', index: colorArray.length, indexValue:legendScale(colorArray.length)})   
  }



  let tickSort = colorArray.sort(function (a: any, b: any) { return b.index - a.index; });

  //axisLeft(scale) d3.axisBottom().scale(x);

    return tickSort.map((item: any, index:any) => {
      return (
        <div key={uuidv4()} style={{display:'flex'}}>
          <span style={{display:'inline-block', paddingRight:4, fontSize: '.7em'}} className={'mapslider-color-span'}>{item.value}</span> 
          <div key={uuidv4()} style={{width: widthSegment, height: 15, backgroundColor: item.color, position: 'relative'}}></div>
        </div>
      )
    })
};

export default MapLegendColorPalette;