import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import settings from "./../../settings.json";
import { useState, useContext, useEffect, useMemo } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { ActivePopupContext } from './../../ActivePopupContext';
import { v4 as uuidv4 } from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { adjustHexBrightness } from './../../features/utils';

const SigFloodOutlook = ({ id, url, opacity, global }: any) => {

  let _currentUrl = 'https://mapservices.weather.noaa.gov/experimental/services/owp_fho/MapServer/WFSServer?request=GetFeature&outputFormat=GEOJSON&service=WFS&typeName=owp_fho:Days_1-7'
   let _sfUrl = `https://mapservices.weather.noaa.gov/vector/services/outlooks/sig_riv_fld_outlk/MapServer/WFSServer?request=GetFeature&srsName=EPSG:4326&outputFormat=GEOJSON&TYPENAME=sig_riv_fld_outlk:Flood_Outlook&service=WFS&MAXFEATURES=400`
  
  const [popupInfo, setPopupInfo] = useState<any>([]);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);

  const [sfUrl, setsfUrl] = useState<any>(_sfUrl);
 

  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;



  useMemo(() => mapInstance && mapInstance.on('click', (event: any) => {
    const queryObject = mapInstance.queryRenderedFeatures(event.point);
    let layerObject = [] as any; 
    for (let i = 0; i < queryObject.length + 1; i++) {
      if (queryObject[i] && queryObject[i].layer && (queryObject[i].layer.id === `${id}-layer`||queryObject[i].layer.id === `${id}-layerG`)) {
        queryObject[i].properties["centroid_longitude"] = event.lngLat.lng;
        queryObject[i].properties["centroid_latitude"] = event.lngLat.lat
        layerObject.push(queryObject[i].properties);
      }
    }
 
     for (let i = 0; i < queryObject.length + 1; i++) {
      if (queryObject[i] && queryObject[i].layer && (queryObject[i].layer.id === `${id}-layerGF`||queryObject[i].layer.id === `${id}-layerS`||queryObject[i].layer.id === `${id}-layerH`||queryObject[i].layer.id === `${id}-layerM`) ) {
        queryObject[i].properties["centroid_longitude"] = event.lngLat.lng;
        queryObject[i].properties["centroid_latitude"] = event.lngLat.lat
        layerObject.push(queryObject[i].properties);
      }
    }

    if (layerObject && layerObject.length > 0) {
      if (layerObject[0]["centroid_longitude"] && !isNaN(layerObject[0]["centroid_longitude"]) && layerObject[0]["centroid_latitude"] && !isNaN(layerObject[0]["centroid_latitude"])) {
        setPopupInfo(layerObject);
        setActivePopup({
          id,
          content: renderPopup(layerObject)
        });
      }
    }
  }), [mapInstance]);



  const renderThumb = (style: any) => {
    const thumbStyle = {
      backgroundColor: 'white'
    };
    return (
      <div style={{ ...style, ...thumbStyle }} />
    );
  }

  let importOrder = [
    "Hazard_Type",
    "Hazard_Abbreviation",
    "000",
    "Max_Status", "max_status", "Max Status", " Max Status", "Max Status ", 
    "Name",
    "Max_Streamflow__cfs_", 

    "MAXFEATURES",
    "Guage_Location",
    "outlook",
    "valid_time",
    "State",
    "Water_Body",    
    "Observed",
    "Units",
    "Flood_Status",
    "Forecast_Guage_Level_Value",
    "Hydrograph",
    "Region",
    "Category",  
    "Gauge_ID",
    "Issued_Time",
    "Alert_Type",
    "Forecast_Office",
    "Ends",
    "Water_Level_In_Need_of_Action",
    "Secondary_Forecast_Value",
    "NWM_Feature_ID",
    "Associated_NWS_LID",
    "Has_Forecast_Point",
    "Message_ID",
    "GIS_File_Date",
    "GIS_Ingest_Date",
    "Longitude",
    "Latitude",
    "centroid_longitude",
    "centroid_latitude"
  ]


const sortByObject: any = importOrder
    .reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
        };
    }, {});

  const popoverHydro = (
    <Popover style={{height: 580, width: 840}} id="mapslider-categories-popover" className="mapslider-categories-popover" key={"mapslider-categories-popover-"}>
      <Popover.Header as="h3">NOAA/National Weather Service Precipitation Analysis</Popover.Header>
      <Popover.Body  className="mapslider-categories-popover-body" >
            <iframe id="iframeModalWindow" style={{height: 480, width: 740}} src="https://water.noaa.gov/gauges/crmf1#official-CRMF1" name="iframe_modal"></iframe>
      </Popover.Body>
    </Popover>
  );

  const pickColor = (item: string) => {
    if(item){
      if(item==='major'){
        return '#bf7fbf'
      } else if(item==='minor'){
        return '#ffff7f'
      } else if(item==='moderate'){
        return '#ff3232'
      } else {
        return 'white'
      }
    } else {
      return 'white'
    }
  }


  const renderFields = (objectMapArray: any) => {
    return objectMapArray.map((objectMap: any, index: any) => {
/*      if(objectMap.Max_Status){
        objectMap["Max Status"] = objectMap.Max_Status;
      }*/
      let sortedFields = Object.entries(objectMap).sort((a: any, b: any) => sortByObject[a[0]] - sortByObject[b[0]]);

      return sortedFields.map((element: any, index: any) => {
        const skipFields = ['layerName', 'counts', 'results', 'radius', 'Message_ID', 'GmlID', 'OBJECTID', 'Object_ID', 'objectid', 'id'];
        if (skipFields.indexOf(element[0]) > -1) {
          return null;
        } else if (element[0] === 'URL') {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'URL:'}</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={element[1]} target={'_blank'}>More information at NOAA's NWS</a>
              </span>
            </p>
          )
        } else if (element[0] === 'Hydrograph') {
          return (
            <div>
           <div>

               </div>
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'URL:'}</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={element[1]+'#uk-switcher-1'} target={'_blank'}>Navigate to NOAA's Hydrograph</a>
              </span>
            </p>
            </div>
           
          )  
        } else if (element[0] === 'Forecast_Office' && element[1] && element[1].length > 2) {
          return (
            <p className="GD-p-comid" key={uuidv4()}>
              <span style={{ opacity: .9 }}>{'Forecast_Office'}:</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>
                <a href={`https://www.weather.gov/${element[1].substr(0, 10)}`} target={'_blank'}>Navigate to NWS {element[1]}</a>
              </span>
            </p>
          )
        } else if (element[0] === 'centroid_latitude') {
          return (
            <div key={uuidv4()} >
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>    {element[1]} </span>
              </p>
              <hr key={uuidv4()} style={{ marginBottom: 20 }} />
              <hr key={uuidv4()} style={{ marginBottom: 10 }} />
            </div>
          )
        }  else if (element[0] === 'Flood_Status') {

          return (
            <div key={uuidv4()} >
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span style={{ color: pickColor(element[1]) }} >{element[1]}</span> 
                </span>
              </p>
              <hr key={uuidv4()} style={{ marginBottom: 10,  width: 200, marginLeft: "20%" }} />
            </div>
          )
        } else if (element[0] === 'Guage_Location') {
       
          return (

            <div key={uuidv4()} style={{ marginTop: 10}}>
                {objectMap && objectMap.Observed && objectMap.Observed!=="null" && ( <h4>Current Observed Values</h4>  )}
                {objectMap && objectMap.Observed && objectMap.Observed==="null" && ( <h4>Forecasted Values</h4>  )}
                {objectMap && objectMap.outlook && objectMap.outlook!=="null" && ( <h4>River Flooding Outlook</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        } else if (element[0] === 'outlook') {
       
          return (
                
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.outlook && objectMap.outlook!=="null" && ( <h4>River Flooding Outlook</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        } else if (element[0] === 'Region') {
       
          return (
                
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.Region && objectMap.Region!=="null" && ( <h4>Flood Hazard Outlook</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        }  else if (element[0] === 'Hazard_Type') {
       
          return (
              
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.Hazard_Type && objectMap.Hazard_Type!=="null" && ( <h4>NWS Hazard Alert</h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span>{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        } else if (element[0] === 'Name') {
       
          return (
              
            <div key={uuidv4()} style={{ marginTop: 10}}>

                {objectMap && objectMap.Max_Status && objectMap.Max_Status!=="null" && ( <h4>5day Max Streamflow </h4>  )}
              <p className="GD-p-comid" key={uuidv4()} >
                <span style={{ opacity: .9 }}>{element[0]}:</span>
                <span style={{ marginLeft: 10, fontSize: '1.5em', opacity: 1 }}>
                  <span  >{element[1]}</span> 
                </span>
              </p>
            </div>
          )
        }  else {
          return (
            <p className="GD-p-comid" key={uuidv4()}>  
              <span style={{ opacity: .9 }}>{element[0]}:</span>
              <span style={{ marginLeft: 10, fontSize: '1.3em', opacity: 1 }}>    {element[1]} </span>
            </p>
          )
        }
      })
    })
  }

  const renderPopup = (info: any) => {
    return (
      <Popup
        longitude={Number(info[0].centroid_longitude)}
        latitude={Number(info[0].centroid_latitude)}
        closeButton={true}
        closeOnClick={true}
        className="wqp"
        onClose={() => {
          setPopupInfo(null);
          setActivePopup({ id: null, content: null });
        }}>
        <div className="popup-container" style={{ paddingBottom: 5, lineHeight: '1px' }}>
          <Scrollbars renderThumbVertical={renderThumb} className="summary-scroll" style={{ width: '100%', height: '100%' }}>

            {renderFields(info)}
            <div style={{ marginBottom: 20 }} />
          </Scrollbars>
        </div>
      </Popup>
    );
  }


  return (
    <div>


    <Source
      id={`${id}-layerS`}
      key={`${id}-layerS`}
      type="geojson"// @ts-ignore
      data={sfUrl}
    >
      {activePopup && activePopup.id === id && activePopup.content}
      <Layer
        paint={{
          "fill-opacity": opacity,
          "fill-outline-color": "darkblue",
          "fill-color": [
                'match',
                    ['get', 'outlook'],
                    'Likely', `#cc33ff`,
                    'Possible', `yellow`,
                    'Occurring', `red`,
                    'minor', `#ff9900`,
                    "green"
            ],
        }}
        beforeId={settings.defaultBeforeId} 
        type="fill" id={`${id}-layerS`}
        source={`${id}-layerS`}
      />


    </Source>





    </div>
  )
}

export default SigFloodOutlook