import { useState, useEffect, useRef, useContext, useCallback } from "react";
import Map, { FullscreenControl, Source, Layer } from "react-map-gl";
import { Form, Accordion } from "react-bootstrap";
import "./styles.scss";
import ReportTitle from "../shared/report-title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { Button } from "react-bootstrap";
import hideMenuRightIcon from "../../../assets/hide-right-menu-icon.svg";
import showMenuRightIcon from "../../../assets/show-right-menu-icon.svg";
import settings from "../../../settings.json";
import RenderLayers from "../shared/RenderLayers";
import RenderLayerMarkers from "../shared/RenderLayerMarkers";
import { AppContext } from "../../../AppContext";
import { RefContext } from "../../../RefContext";
import { HUC8GeoPoint } from "../../../types/HUC8GeoPoint";
import { CatalogsContext } from "../../../CatalogsProvider";
import { qualityReferences } from "../shared/qualityReferences";
import Breadcrumb from "../shared/Breadcrumb";
import { putViewportIntoStorage, updateCentroidState } from "../../utils";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import MapLegend from "../shared/MapLegend";
import MapSlider from "../shared/MapSlider";
import MapAddition from  '../shared/MapAddition';
import FilterState from "../shared/FilterState";
import ReportHeader from "../shared/ReportHeader";
import ReportFooter from "../shared/ReportFooter";
import RenderLegend from "./RenderLegend";
import Draggable from "react-draggable";
import { getViewportFromStorage } from "../../utils";
import MCToolset from  '../shared/MCToolset';
import "mapbox-gl/dist/mapbox-gl.css";
// @ts-ignore
import { globalKey } from "rsuite/esm/utils";
import { startingValues } from '../shared/report-data';
import ZoomWidget from "../shared/ZoomWidget";


export default function NationalLiveWaterQualityMap(props: any) {
  const { global } = props;
  useEffect(() => {
    global.setUserLogged(true);
  });

  const catalogsContext = useContext(CatalogsContext);
  const { updateContext } = useContext(AppContext);
  const { setCurrentRef } = useContext(RefContext);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const mediaQuery = window.matchMedia("(max-width: 877px)");
  const mapRef = useRef<any>();

  const [prevViewport, setPreviousViewport] = useState<any>();
  let startingViewport = startingValues('viewport', prevViewport, global, catalogsContext.huc8Catalog); 

  useEffect(() => {
    setCurrentRef?.(mapRef);
  }, [mapRef]);

  
  useEffect(() => {
    const lastViewport = getViewportFromStorage();
    setTimeout(() => {
      if (lastViewport) setPreviousViewport(lastViewport);
    }, 100);
  }, []);

  useEffect(() => {
    const mainRow = document.getElementById("mainRow");
    if (mainRow) {
      mainRow.style.overflow = "hidden";
    }

    return () => {
      if (mainRow) {
        mainRow.style.overflow = "auto";
      }

    };
  }, []);
  
  const getCurrentHuc8LongLat = (
    selectedHUC8Value: string,
    catalog?: any[]
  ): HUC8GeoPoint | undefined => {
    if (catalog) return catalog.find((x) => x.huc8 == selectedHUC8Value);
    return catalogsContext.huc8Catalog.find((x) => x.huc8 == selectedHUC8Value);
  };

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;


  return (
    <FullScreenComp handle={fullscreenhandle}>
      <div
        id="NationalLiveWaterQualityMap"
        className="report-screen"
        style={{ width: "100%", height: "100vh", marginTop: 0 }}
      >
        <ReportTitle
          title="Global Live Water Map"
          global={global}
          resptitle={mediaQuery.matches}
        />
        <ReportHeader
          global={global}
          reportID={"NationalLiveWaterQualityMap"}
          data={[]}
          fullScreenClickHandle={fullscreenhandle.enter}
          breadcrumboff={false}
          titleoff={true}
          filterAppliedOff={true}
          absolute={true}
        />
        {/* {global.hucsSelected && <h1 className="huc-title">HUC: {appContext.selectedHUC8}</h1>} */}
        <RenderLegend global={global} />

        <RightHeader global={global} />
        <Map
          longitude={global.viewport.longitude}
          latitude={global.viewport.latitude}
          zoom={global.viewport.zoom}
          mapboxAccessToken={settings.maboxKey}
          preserveDrawingBuffer={true}
          initialViewState={global.viewport}
          projection={global.globeView ? "globe" : "mercator" as any}
          ref={mapRef}
          onClick={(e) => {
            global.onMapClick(e);
          }}
          onMove={(e) => {
            putViewportIntoStorage({
              longitude: e.viewState.longitude,
              latitude: e.viewState.latitude,
              zoom: e.viewState.zoom,
            });
            global.setViewport({
              longitude: e.viewState.longitude,
              latitude: e.viewState.latitude,
              zoom: e.viewState.zoom,
            });
          }}
          mapStyle={global.mapStyle}
        >
        <MapAddition 
          global={global} 
          mapRef={mapRef}
          displayControlsDefault={false}
          drawPlacement={'bottom-left'}
          position={'low-adjusted'}
          zipOff={true}
        />
        <ZoomWidget zoom={global.viewport.zoom}/>
        </Map>

        <MapSlider global={global} />
      </div>
    </FullScreenComp>
  );
}

function RightHeader(props: any) {
  const { global } = props;
  const [date, setDate] = useState(moment().format("MMMM DD YYYY"));
  const [time, setTime] = useState(moment().format("hh:mm.ss"));

  const mediaQuery = window.matchMedia("(min-width: 877px)");

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(moment().format("MMMM DD YYYY"));
      setTime(moment().format("hh:mm.ss"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const onClickMenu = () => {
    if (global.MenuRightOpen) global.SetMenuRightOpen(false);
    else global.SetMenuRightOpen(true);
  };

  return (
    <div
      className={
        mediaQuery.matches ? "right-header" : "right-header-lower right-header"
      }
    >
      {/* @ts-ignore */}
    <Button className="btn-right-menu" onClick={onClickMenu}>
      <img
        src={!global.MenuRightOpen ? showMenuRightIcon : hideMenuRightIcon}
        alt={global.MenuRightOpen ? "Hide Menu Icon" : "Show Menu Icon"}
      />
    </Button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      {/* <div className="live rounded-pill">
        <FontAwesomeIcon icon={faCircle} size="xs" />
        &nbsp;live
      </div> */}
      &nbsp;
      <div className="right-header-tracker">
        <span>{date}</span>
        <span>{time}</span>
      </div>
    </div>
  );
}
