import { Source, Layer, Popup, FillLayer, CircleLayer, LineLayer } from 'react-map-gl';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import settings from "./../../settings.json";
import { useState, useContext, useEffect, useMemo } from 'react';
import { RefContext } from "./../../RefContext";
import { PointContext } from "./../../PointContext";
import { ActivePopupContext } from './../../ActivePopupContext';
import { v4 as uuidv4 } from 'uuid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { adjustHexBrightness } from './../../features/utils';
import EvaluationLayers from "./EvaluationLayers";

const EMI = ({ id, url, opacity, global }: any) => {

  let _currentUrl = 'https://mapservices.weather.noaa.gov/experimental/services/owp_fho/MapServer/WFSServer?request=GetFeature&outputFormat=GEOJSON&service=WFS&typeName=owp_fho:Days_1-7'
  let _sfUrl = `https://basemap.nationalmap.gov/arcgis/rest/services/USGSHydroCached/MapServer/tile/{x}/{y}/{z}`


  let initialLayer = 'Emergency Response Imagery';
  let siteItem = EvaluationLayers && EvaluationLayers.find((item: any) => item.name === initialLayer)
  if(siteItem && siteItem.options && !siteItem.currentOption && siteItem.options > 0){
       let optionItem = siteItem.options[0];
       siteItem.currentOption = optionItem;
  }

  const [popupInfo, setPopupInfo] = useState<any>([]);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);
  const [category, setCategory] = useState<any>(siteItem && siteItem.name);
  const [categoryDetails, setCategoryDetails] = useState<any>(siteItem);

  const [currentUrl, setCurrentUrl] = useState<any>(_currentUrl);
  const [sfUrl, setsfUrl] = useState<any>(_sfUrl);
 
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;


  useEffect(() => {
      if(global.layerMode && global.layerMode.layer==='EMI'){
        setCategory(global.layerMode.category);
        setCategoryDetails(global.layerMode.site)
      }
  }, [global.layerMode]);


  useEffect(() => {
      if(categoryDetails){
        if(categoryDetails.options && categoryDetails.currentOption && categoryDetails.optionsurl){        
          let newURL = categoryDetails.optionsurl.replace("${optionid}", categoryDetails.currentOption.id) 
          setsfUrl(newURL);
        } else {
          setsfUrl(categoryDetails.url);
        }
      }
  }, [categoryDetails, categoryDetails?.currentOption]);

  const getCoverageBounds = (source:string) => {  //fl -87.63470035600356,24.51490854927549,-80.03257567895679,31.000809213282125
    if(categoryDetails && categoryDetails.currentOption && categoryDetails.currentOption.bounds){
      return categoryDetails.currentOption.bounds;
    }

    if(categoryDetails && categoryDetails.coverage){
      if(categoryDetails.coverage==='CONUS'){ //-125, -65, 24, 50)
        return [-124, 23, -60, 50]
      } else if(categoryDetails.coverage==='Global'){
        return [-180,-85.051129,180,85.051129]
      } else if(categoryDetails.coverage==='Global nc'){
        return [-180,-85.051129,180,85.051129]
      }
    }
    return [-180,-85.051129,180,85.051129]
  }

  // maxzoom={(categoryDetails && categoryDetails.maxzoom) ? categoryDetails.maxzoom : 22}
/*
                  minzoom={(categoryDetails && categoryDetails.minzoom) ? categoryDetails.minzoom : 0} 
                  maxzoom={(categoryDetails && categoryDetails.maxzoom) ? categoryDetails.maxzoom : 22} 
*/
    const renderSources = () => {
      if(categoryDetails && categoryDetails.type && categoryDetails.type!=='raster'){
        //console.log(categoryDetails.type)
        if(categoryDetails.type==='rasterimage'){
          return (
                  <Source
                    id={`${id}-layerR`}
                    key={`${id}-layerR`}
                    type="raster"
                    maxzoom={(categoryDetails && categoryDetails.maxzoom) ? categoryDetails.maxzoom : 22}
                    bounds={getCoverageBounds('source')}
                    tileSize={256}
                    tiles={[sfUrl]}>
                    <Layer      
                      beforeId={settings.defaultBeforeId}
                      paint={{ 'raster-opacity': opacity }}
                      type="raster" id={`${id}-layerR`}
                      source={`${id}-layerR`}       
                    />
                  </Source>
            )
        }
      } else {
        return (
        
                <Source
                  id={`${id}-layerR`}
                  key={`${id}-layerR`}
                  type="raster"

                  bounds={getCoverageBounds('source')}
                  tileSize={256}
                  tiles={[sfUrl]}>
                  <Layer      
                    beforeId={settings.defaultBeforeId}
                    paint={{ 'raster-opacity': opacity }}
                    type="raster" id={`${id}-layerR`}
                    source={`${id}-layerR`}       
                  />
                </Source>
            
          )
      }
    }



  return (
    <>
        {renderSources()}
    </>
  )
}
export default EMI