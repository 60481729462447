import  React, { useMemo, memo, useCallback, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Text } from '@react-three/drei';
import { Col } from 'react-bootstrap';

const CylindricalLithologyChart = ({ data }: any) => {
 // Function to adjust color brightness
 const adjustColorBrightness = (color: string, factor: number) => {
   const hex = color.replace("#", "");
   const bigint = parseInt(hex, 16);
   const r = Math.min(255, Math.max(0, ((bigint >> 16) & 255) + factor));
   const g = Math.min(255, Math.max(0, ((bigint >> 8) & 255) + factor));
   const b = Math.min(255, Math.max(0, (bigint & 255) + factor));
   return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
 };


 const renderLithologySegments = () => {
   // Calculate total height of all segments for centering
   const totalHeight = data.reduce(
     (sum: number, segment: any) => sum + segment.height,
     0
   );

   return data.map((segment: any, index: any) => {
     const { color, height, startHeight, radius, label } = segment;
     const adjustedColor = adjustColorBrightness(color, 40); // Lighten the color
     const fineTuneOffset = -40
     const yOffset = -(startHeight + height / 2) + totalHeight / 2 + fineTuneOffset;

    return (
        <React.Fragment key={index}>
          <mesh position={[0, yOffset, 0]}>
            <cylinderGeometry args={[radius, radius, height, 8]} />
            <meshStandardMaterial color={adjustedColor} />
          </mesh>
            <Text
              position={[radius + 10, yOffset, 0]}
              fontSize={3}
              color="black"
              anchorX="left"
              anchorY="middle"
            >
                {label}
            </Text>
        </React.Fragment>
      );
   });
 }

 useEffect(() => {
  const canvas = document.querySelector('canvas');
  if (!canvas) return;

  const handleContextLost = (event: any) => {
    event.preventDefault();
    console.warn('WebGL context lost');
  };

  const handleContextRestored = () => {
    console.log('WebGL context restored');
  };

  canvas.addEventListener('webglcontextlost', handleContextLost);
  canvas.addEventListener('webglcontextrestored', handleContextRestored);

  return () => {
    canvas.removeEventListener('webglcontextlost', handleContextLost);
    canvas.removeEventListener('webglcontextrestored', handleContextRestored);
  };
}, []);

 return (
   <Col className="col-6 d-flex flex-row justify-content-around align-items-center lithology-chart">
     <Col className="col-3 canvas-container">
       <Canvas
         camera={{ position: [0, 0, 150], fov: 40 }} // Adjust camera Y-axis
       >
         <ambientLight intensity={0.5} />
         <pointLight position={[10, 10, 10]} />
            {renderLithologySegments()}
         <OrbitControls />
       </Canvas>
     </Col>
   </Col>
 );
};


export default memo(CylindricalLithologyChart);