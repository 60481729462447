import { ReportConfiguration } from './types/ReportConfiguration';
import Routes from './ReportsRoutes';


const ReportsConfigs: ReportConfiguration[] = [
  {
    name: "Oregon Water Resources Department Recorder Well",
    route: Routes.OSUWellsReport,
    parentReportName: 'Home',
    sourceText: "GWIS Map Tool",
    sourceLink: '/www.epa.gov/ground-water-and-drinking-water/community-water-system-service-area-boundaries',
    sourceTextSecondary: '',
    sourceLinkSecondary: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: false,
      hasTimeRangePicker: true
    },
  },
  {
    name: "EPA Water Service Area Boundaries",
    route: Routes.EPAWaterServiceAreaBoundaries,
    parentReportName: 'Home',
    sourceText: "Community Water System Service Area Boundaries | US EPA",
    sourceLink: 'https://www.epa.gov/ground-water-and-drinking-water/community-water-system-service-area-boundaries',
    sourceTextSecondary: '',
    sourceLinkSecondary: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: false,
    },
  },
  {
    name: "Global Hydrobasins Combined",
    route: Routes.HydrobasinsCombinedReport,
    parentReportName: 'Home',
    sourceText: "HydroBASINS (hydrosheds.org)",
    sourceLink: 'https://www.hydrosheds.org/products/hydrobasins',
    sourceTextSecondary: '',
    sourceLinkSecondary: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: false
    },
  },
  {
    name: "Global Hydrobasins",
    route: Routes.HydrobasinsReport,
    parentReportName: 'Home',
    sourceText: "HydroBASINS (hydrosheds.org)",
    sourceLink: 'https://www.hydrosheds.org/products/hydrobasins',
    sourceTextSecondary: '',
    sourceLinkSecondary: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: false
    },
  },
  {
    name: "WISE Water Framework Directive Database",
    route: Routes.WiseReport,
    parentReportName: 'Home',
    sourceText: "WISE Water Framework Directive Database (europa.eu)",
    sourceLink: 'https://www.eea.europa.eu/en/datahub/datahubitem-view/dc1b1cdf-5fa0-4535-8c89-10cc051e00db',
    sourceTextSecondary: '',
    sourceLinkSecondary: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: false
    },
  },
  {
    name: "Manuels Branch E.coli Trends",
    route: Routes.MECReport,
    parentReportName: 'Home',
    sourceText: "Manuel Branch | River Details - CHNEP.WaterAtlas.org (usf.edu)",
    sourceLink: 'https://chnep.wateratlas.usf.edu/waterbodies/rivers/9000183/',
    sourceTextSecondary: 'Calusa Waterkeeper',
    sourceLinkSecondary: 'https://calusawaterkeeper.org/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: true
    },
  },
  {
    name: "Manuel's Branch FIB Sampling Data and Trends",
    route: Routes.FIBReport,
    parentReportName: 'Home',
    sourceText: "Manuel's Branch FIB Sampling Data and Trends",
    sourceLink: 'https://calusawaterkeeper.org/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasMECDateRangeFilter: true
    },
  },
  {
    name: "Neon Science Sensor Location Data",
    route: Routes.NSGReport,
    parentReportName: 'Home',
    sourceText: "NEON | Chemical Properties of Groundwater",
    sourceLink: 'https://data.neonscience.org/data-products/DP1.20092.001',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasTimeRangePicker: true
    },
  },
  {
    name: "Oregon Water Quality Portal",
    route: Routes.OWQPReport,
    parentReportName: 'Home',
    sourceText: "Oregon Water Quality Portal",
    sourceLink: 'https://www.oregon.gov/deq/wq/pages/owdp.aspx',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
    },
  },
  {
    name: "Calusa Waterkeeper: Fecal Indicator Bacteria (FIB) Sampling",
    route: Routes.FBFIBReport,
    parentReportName: 'Home',
    sourceText: "Bacteria Monitoring - Calusa Waterkeeper",
    sourceLink: 'https://calusawaterkeeper.org/issues/bacteria-monitoring/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasFIBFilter: true,
    },
  },
  {
    name: "Florida Healthy Beaches Program",
    route: Routes.FHBPReport,
    parentReportName: 'Home',
    sourceText: "Florida Healthy Beaches Program | Florida Department of Health",
    sourceLink: 'https://www.floridahealth.gov/environmental-health/beach-water-quality/county-detail.html?County=Collier&Zip=34112-4961',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
    },
  },
  {
    name: "Cleveland Water Alliance",
    route: Routes.CWAReport,
    parentReportName: 'Home',
    sourceText: "Cleveland Water Alliance",
    sourceLink: 'https://www.clevelandwateralliance.org/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasCWAParams: true,
      hasTimeRangePicker: true,
    },
  },
  {
    name: "Protected Areas World Database",
    route: Routes.PAWD,
    parentReportName: 'Home',
    sourceText: 'World Database on Protected Areas (WDPA)',
    sourceLink: 'https://screeningtool.geoplatform.gov/en',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: "Climate and Economic Justice Screening Tool",
    route: Routes.CEJST,
    parentReportName: 'Home',
    sourceText: 'Climate and Economic Justice Screening Tool',
    sourceLink: 'https://screeningtool.geoplatform.gov/en',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: "USGS Monthly Public Water Usage (By HUC12)",
    route: Routes.USGSHuc12,
    parentReportName: 'Home',
    sourceText: 'USGS Monthly Public Water Usage (By HUC12)',
    sourceLink: 'https://www.usgs.gov/data/public-supply-water-use-reanalysis-2000-2020-period-huc12-month-and-year-conterminous-united',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: "USGS Monthly Public Water Usage (MGD) by City 2000-2020",
    route: Routes.USGSWUCReport,
    parentReportName: 'Home',
    sourceText: 'USGS Monthly Public Water Usage (MGD) by City 2000-2020',
    sourceLink: 'https://www.usgs.gov/data/public-supply-water-use-reanalysis-2000-2020-period-huc12-month-and-year-conterminous-united',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasUSGSYears: true
    },
  },
  {
    name: 'National 303D Impaired Waters Report',
    route: "/WaterImpairmentsReport",
    parentReportName: 'Home',
    sourceText: 'National 303D Impaired Waters Layer',
    sourceLink: 'https://catalog.data.gov/dataset/epa-office-of-water-ow-303d-listed-impaired-waters-nhdplus-indexed-dataset1',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'National 303D Impaired Waters Layer - Points',
    route: "/WaterImpairementsPoints",
    parentReportName: 'Home',
    sourceText: 'National 303D Impaired Waters Layer',
    sourceLink: 'https://catalog.data.gov/dataset/epa-office-of-water-ow-303d-listed-impaired-waters-nhdplus-indexed-dataset1',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'National 303D Impaired Waters Layer - Rivers and Streams',
    route: "/WaterImpairementsStreams",
    parentReportName: 'Home',
    sourceText: 'National 303D Impaired Waters Layer',
    sourceLink: 'https://catalog.data.gov/dataset/epa-office-of-water-ow-303d-listed-impaired-waters-nhdplus-indexed-dataset1',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'National 303D Impaired Waters Layer - Lakes',
    route: "/WaterImpairementsLakes",
    parentReportName: 'Home',
    sourceText: 'National 303D Impaired Waters Layer',
    sourceLink: 'https://catalog.data.gov/dataset/epa-office-of-water-ow-303d-listed-impaired-waters-nhdplus-indexed-dataset1',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Surface Water Mexico',
    route: Routes.SurfacewaterMexico,
    parentReportName: 'Home',
    sourceText: 'Surface Water Mexico',
    sourceLink: 'https://www.gob.mx/conagua/acciones-y-programas/aguas-del-valle-de-mexico',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Groundwater Mexico',
    route: Routes.GroundwaterMexico,
    parentReportName: 'Home',
    sourceText: 'Groundwater Mexico',
    sourceLink: 'https://www.gob.mx/conagua/acciones-y-programas/aguas-del-valle-de-mexico',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Water Conflict',
    route: Routes.WaterConflict,
    parentReportName: 'Home',
    sourceText: 'Water Conflict Chronology - Pacific Institute (pacinst.org)',
    sourceLink: 'https://pacinst.org/water-conflict-chronology',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Global Biodiversity Information Facility',
    route: Routes.GBIF,
    parentReportName: 'Home',
    sourceText: 'Global Biodiversity Information Facility',
    sourceLink: 'https://planetarycomputer.microsoft.com/dataset/gbif',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Indonesian HUCs',
    route: '/IndoHUCs',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Indonesian Watersheds',
    route: '/IndoWatersheds',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Aqueduct Water Risk Atlas v4.0',
    route: '/AqueductWaterRiskAtlasV4',
    parentReportName: 'Home',
    sourceText: 'Aqueduct Water Risk Atlas v4.0',
    sourceLink: 'https://www.wri.org/publication/aqueduct-30',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Climate Vulnerability Index',
    route: '/ClimateVulnerabilityIndex',
    parentReportName: 'Home',
    sourceText: 'Climate Vulnerability Index',
    sourceLink: 'https://climatevulnerabilityindex.org/methodology/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: '',
    route: '/Chat',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Excessive Rainfall Potential Forecast',
    route: '/RainfallForecast',
    parentReportName: 'Home',
    sourceText: 'Excessive Rainfall Potential Forecast',
    sourceLink: 'https://www.wpc.ncep.noaa.gov/qpf/excessive_rainfall_outlook_ero.php',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Protected Areas World Database',
    route: '/ProtectedAreasWorldDatabase',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Aqueduct Water Risk Atlas v3.0',
    route: '/AqueductWaterRiskAtlas',
    parentReportName: 'Home',
    sourceText: 'Aqueduct Water Risk Atlas v3.0',
    sourceLink: 'https://www.wri.org/publication/aqueduct-30',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Impaired Waters List 303D (WI, VA)',
    route: '/WIImpairedWatersList',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Top 100 Polluters',
    route: '/Top100Polluters',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Historical Fire Data',
    route: '/HistoricalFireData',
    parentReportName: 'Home',
    sourceText: 'NIFC Maps',
    sourceLink: 'https://www.nifc.gov/fire-information/maps',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Wildfire Boundaries Data',
    route: '/WildfireBoundaries',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'GFS Weather Historical',
    route: '/GFSHuc12Forecast',
    parentReportName: 'Home',
    sourceText: 'GFS Weather Historical',
    sourceLink: 'https://www.ncei.noaa.gov/products/weather-climate-models/global-forecast',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false,
      hasSingleDateFilter: true
    },
  },
  {
    name: 'UN Gem Station Raw Data',
    route: '/MenuGemstatRaw',
    parentReportName: 'Home',
    sourceText: 'GEMStat - The global water quality database',
    sourceLink: 'https://gemstat.org/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'UN Gem Station Raw Data',
    route: '/GemstatRaw',
    parentReportName: 'Home',
    sourceText: 'GEMStat - The global water quality database',
    sourceLink: 'https://gemstat.org/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  {
    name: 'Australia Bore & Water Data',
    route: '/AusBoreData',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter: false
    },
  },
  { 
    name: 'Global Live Water Map',
    route: '/',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasPopFilter:true
    },
  },
  {
    name: 'National Stormwater Quality Database',
    route: Routes.NationalStormWaterQualityDatabase,
    parentReportName: 'Surface Water',
    sourceText: 'National Stormwater Quality Database (NSQD)',
    sourceLink: 'https://hero.epa.gov/hero/index.cfm/reference/details/reference_id/7681652',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false, 
      yearsFilterType: false, 
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasStatesFilter: true
    },
  },
  {
    name: 'National Stream Quality Accounting Network',
    route: Routes.NationalStreamQualityAccountingNetwork,
    parentReportName: 'Surface Water',
    sourceText: 'USGS National Stream Quality Accounting Network (NASQUAN)',
    sourceLink: 'https://www.usgs.gov/centers/dakota-water-science-center/science/national-stream-quality-accounting-network-nasqan',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false, 
      yearsFilterType: false, 
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasStatesFilter: false
    },
  },
  {
    name: 'EPA Major Permit Holders',
    route: Routes.EPAMajorPermitHolders,
    parentReportName: 'Surface Water',
    sourceText: 'EPA National Pollutant Discharge Elimination System',
    sourceLink: 'https://www.epa.gov/npdes',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      yearsFilterType: true,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
    },
  },
  {
    name: 'Toxics Release Inventory Report',
    route: Routes.ToxicsReleaseInventoryReport,
    parentReportName: 'Surface Water',
    sourceText: 'EPA Toxics Release Inventory TRI',
    sourceLink: 'https://www.epa.gov/toxics-release-inventory-tri-program',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: true,
      hasIndustrySectorFilter: true,
      hasYearsFilter: true,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
    },
  },
  {
    name: 'Daily Lake Mead Levels',
    route: Routes.DailyLakeMeadLevelsReport,
    parentReportName: 'Surface Water',
    sourceText: 'US Lakes Daily Lake Mead Levels',
    sourceLink: 'https://mead.uslakes.info/Level/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasDateFilter: true,
      hasMeasurementsFilter: false,
    },
  },
  {
    name: 'Drinking Water True QI Report',
    route: Routes.DrinkingWaterTrueQIReport,
    parentReportName: 'Drinking Water or Water Treatment',
    sourceText: 'Consumer Confidence Reports',
    sourceLink: 'https://www.epa.gov/ground-water-and-drinking-water/national-primary-drinking-water-regulations',
    printTable: 'horizontalPageBreak',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      hasLocationsFilter: false,
      hasPwsidFilter: true,
      hascheckFilter: true,
      hasMeasurementsFilter: false,
      hasPopFilter: true
    },
  },
  {
    name: 'AG Water True QI Forecast',
    route: Routes.AGWaterTrueQIForecast,
    parentReportName: 'Surface Water',
    sourceText: 'True Elements AI Prediction Engine',
    sourceLink: 'https://trueelements.com/our-service/',
    filters: {
      hasDateTimeRangeFilter: true,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Elements',
    },
  },
  {
    name: 'Storm Water True QI Forecast',
    route: Routes.StormWaterTrueQIForecast,
    parentReportName: 'Surface Water',
    sourceText: 'True Elements AI Prediction Engine',
    sourceLink: 'https://trueelements.com/our-service/',
    filters: {
      hasDateTimeRangeFilter: true,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Contaminants',
    },
  },
  {
    name: 'Discharge Monitoring Report',
    route: Routes.DischargeMonitoring,
    parentReportName: 'Surface Water',
    sourceText: 'EPA Discharge Monitoring Report',
    sourceLink: 'https://www.epa.gov/compliance/discharge-monitoring-report-quality-assurance-study-program',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      labelFilter1: 'Pollutants',
      yearsFilterType: true,
    },
  },
  {
    name: 'Permit Discharge Report',
    route: Routes.PermitDischargeReport,
    parentReportName: 'Surface Water',
    sourceText: 'EPA National Pollutant Discharge Elimination System',
    sourceLink: 'https://www.epa.gov/npdes',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      labelFilter1: 'Permit Status',
      hasChemicalsFilter: true,
      labelChemicalsFilter: 'Sic Codes',
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelLocationsFilter: 'Facilities',
      yearsFilterType: false,
    },
  },
  {
    name: 'Groundwater Levels',
    route: Routes.GroundwaterLevels,
    parentReportName: 'Drinking Water or Water Treatment',
    sourceText: 'USGS Water Resources- NWIS - Groundwater Database',
    sourceLink: 'https://waterdata.usgs.gov/nwis/gw',
    filters: {
      hasDateTimeRangeFilter: true,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Data Type',
    },
  },
  {
    name: "What's In My Drinking Water",
    route: Routes.WhatsInMyDrinkingWater,
    parentReportName: 'Drinking Water or Water Treatment',
    sourceText: 'EPA Unregulated Contaminant Monitoring Report 1-5 (2001-2023)',
    sourceLink: 'https://www.epa.gov/dwucmr',
    filters: {
      hasDateTimeRangeFilter: true,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: true,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Report Type',
      labelLocationsFilter: 'Public Water Systems',
      labelChemicalsFilter: 'Contaminants',
    },
  },
  {
    name: "PFAS Environmental",
    route: Routes.PfasEnvironmental,
    parentReportName: 'PFAS',
    sourceText: 'EPA National PFAS Datasets',
    sourceLink: 'https://echo.epa.gov/tools/data-downloads/national-pfas-datasets',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: true,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Report Type',
      labelLocationsFilter: 'Location',
      labelChemicalsFilter: 'PFAS Chemical',
    },
  },
  {
    name: "PFAS Drinking Water",
    route: Routes.PfasDrinkingWater,
    parentReportName: 'PFAS',
    sourceText: 'EPA National PFAS Datasets',
    sourceLink: 'https://echo.epa.gov/tools/data-downloads/national-pfas-datasets',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: true,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Detection',
      labelLocationsFilter: 'Location',
      labelChemicalsFilter: 'Contaminant',
    },
  },
  {
    name: "PFAS Production",
    route: Routes.PfasProduction,
    parentReportName: 'PFAS',
    sourceText: 'EPA National PFAS Datasets',
    sourceLink: 'https://echo.epa.gov/tools/data-downloads/national-pfas-datasets',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: true,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Report Type',
      labelLocationsFilter: 'Location',
      labelChemicalsFilter: 'PFAS Chemical',
    },
  },
  {
    name: "Risk Screening Environmental Indicators",
    route: Routes.RiskScreening,
    parentReportName: 'Drinking Water or Water Treatment',
    sourceText: "EPA's Risk Screening Environmental Indicators (RSEI)",
    sourceLink: 'https://www.epa.gov/rsei',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: true,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      labelFilter1: 'Report Type',
      labelLocationsFilter: 'State',
    },
  },
  {
    name: "Climate Precipitation Calculator",
    route: Routes.ClimatePrecipitationCalculator,
    parentReportName: 'TrueQI Scenario Forecaster',
    sourceText: 'NOAA Atlas 14 Point Precipitation Frequency Estimates',
    sourceLink: 'https://www.weather.gov/owp/hdsc',
    sourceTextSecondary: 'NOAA GHCND Daily Summaries',
    sourceLinkSecondary: 'https://www.ncei.noaa.gov/products/land-based-station/global-historical-climatology-network-daily',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: true,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: false,
      labelFilter1: 'Storm Recurrence',
      labelLocationsFilter: 'Facilities',
    },
  },
  { 
    name: 'TrueQI Site Assessment Reporter',
    route: Routes.Risk,
    parentReportName: 'TrueQI Site Assessment Reporter',
    sourceText: 'Risk Assessment',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      labelFilter1: 'Sector',
      hasMeasurementsFilter: false,
      labelMeasurementsFilter: 'Industry',
      labelChemicalsFilter: 'Industry',
      hasAccordionFilter: true,
    },
  },
  { 
    name: 'Riverine Stress',
    route: Routes.GroundwaterDetermination,
    parentReportName: 'TrueQI Scenario Forecaster',
    // @ts-ignore
    sourcePreText  : "Generated with True Element's Scenario Forecasting Engine based on the ",
    sourceText: 'Intergovernmental Panel on Climate Change (IPCC)',
    sourceLink: 'https://www.ipcc.ch/',
    sourceTextSecondary: 'NOAA',
    sourceLinkSecondary: 'https://www.gfdl.noaa.gov/earth-system-esm4/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      labelFilter1: 'Sector',
      hasMeasurementsFilter: false,
      labelMeasurementsFilter: 'Industry',
      labelChemicalsFilter: 'Industry',
      hasAccordionFilter: false,
    },
  },
  { 
    name: 'Monitoring Report',
    route: Routes.WaterMonitoring,
    parentReportName: 'Water Monitoring',
    sourceText: 'Risk Assessment',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      labelFilter1: 'Measurements',
      hasMeasurementsFilter: false,
    },
  },
  { 
    name: 'Surface Water Flow Rate',
    route: Routes.SurfaceWaterFlowRate,
    parentReportName: 'Surface Water',
    sourceText: 'USGS WaterWatch',
    sourceLink: 'https://waterwatch.usgs.gov/',
    filters: {
      hasDateTimeRangeFilter: true,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: true,
      hasFLElementsFilter: true,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      labelFilter1: 'Measurements',
      hasMeasurementsFilter: false,
    },
  },
  { 
    name: 'Dashboard',
    route: '/Dashboard',
    parentReportName: 'Home',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      labelFilter1: 'Measurements',
      hasMeasurementsFilter: false,
    },
  },
  {
    name: 'Live Water Sensors (USGS)',
    route: Routes.LiveWaterSensors,
    parentReportName: 'Surface Water',
    sourceText: 'USGS WaterWatch',
    sourceLink: 'https://waterwatch.usgs.gov',
    filters: {
      hasDateTimeRangeFilter: true,
      hasHistoricalRangeFilter: false,
      removeForecastFromHistoricalRangeFilter: true, //To remove dropdown with [Last, Next] options from "Historical Date Range".
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: true,
      hasMeasurementsFilter: true,
      labelFilter1: 'Measurements',
    },
  },
  {
    name: "What's in my Watershed?",
    route: Routes.WhatsInMyWatershed,
    parentReportName: 'Surface Water',
    sourceText: 'EPA Watershed Index Online (WSIO)',
    sourceLink: 'https://www.epa.gov/wsio',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
    },
  },
  {
    name: 'Facility Registry System/Service',
    route: Routes.FacilityRegistrySystem,
    parentReportName: 'Surface Water',
    sourceText: 'EPA Facility Registry Service/System',
    sourceLink: 'https://www.epa.gov/frs',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      removeForecastFromHistoricalRangeFilter: true, //To remove dropdown with [Last, Next] options from "Historical Date Range".
      hasHistoricalRangeForecastFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
      hasSICCodesFilter: true,
      labelFilter1: 'SIC Codes',
    },
  },
  { 
    name: 'Chemical Data Reporting',
    route: Routes.ChemicalDataReporting,
    parentReportName: 'Surface Water',
    sourceText: '',
    sourceLink: '',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: true,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
    },
  },
  { 
    name: 'Drinking Water Violation Status Report',
    route: Routes.ViolationData,
    parentReportName: 'Drinking Water or Water Treatment',
    sourceText: 'Enforcement and Compliance History Online | US EPA',
    sourceLink: 'https://echo.epa.gov/',
    filters: {
      hasDateTimeRangeFilter: false,
      hasHistoricalRangeFilter: false,
      hasHistoricalRangeForecastFilter: false,
      removeForecastFromHistoricalRangeFilter: false,
      hasFLElementsFilter: false,
      hasChemicalsFilter: false,
      hasIndustrySectorFilter: false,
      hasYearsFilter: false,
      hasLocationsFilter: false,
      hasMeasurementsFilter: false,
    },
  },
];

export default ReportsConfigs;
